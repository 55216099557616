import { Link } from 'gatsby';
import React from 'react';
import headerImage from '../../../contents/customer-stories/assets/asana/featured.jpg';
import backgroundImage from '../../images/customer-stories/header-background.svg';
import PageHeader from '../layout/PageHeader';
import ArrowGoTo from '../ui/ArrowGoTo';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import styles from './CustomerStoriesHeader.module.scss';

const CustomerStoriesHeader: React.FC = () => (
  <>
    <PageHeader
      title="You’re in great company"
      description="More than 7,000 innovative companies choose Slab to manage and share knowledge across their teams"
    />
    <div className={styles.featuredContainer}>
      <img
        className={styles.backgroundImage}
        src={backgroundImage}
        alt=""
        role="presentation"
      />
      <Link to="/customer-stories/asana/" className={styles.featuredCard}>
        <div
          className={styles.featuredCoverImage}
          style={{ backgroundImage: `url(${headerImage})` }}
        />
        <div>
          <div className={styles.featuredContent}>
            <div className={styles.featuredHeader}>
              <Text muted caps small semiBold>
                Featured Company
              </Text>
              <h3>Asana</h3>
            </div>
            <Spacing top={4} bottom={5}>
              Discover how Asana empowered employees and streamlined information
              sharing by switching to Slab, transforming documentation into a
              data-driven content strategy.
            </Spacing>
            <ArrowGoTo>Read story</ArrowGoTo>
          </div>
        </div>
      </Link>
    </div>
  </>
);

export default CustomerStoriesHeader;
