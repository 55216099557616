import React from 'react';
import styles from './CustomerStoriesSectionHeader.module.scss';

interface CustomerStoriesSectionHeaderProps {
  title: string;
  description?: string;
}

const CustomerStoriesSectionHeader: React.SFC<
  CustomerStoriesSectionHeaderProps
> = ({ title, description }) => (
  <div className={styles.header}>
    <h3>{title}</h3>
    {description}
  </div>
);

export default CustomerStoriesSectionHeader;
