import React from 'react';
import { CustomerStory } from '../../types';
import CustomerStoryCard from './CustomerStoryCard';
import styles from './CustomerStoryGrid.module.scss';

interface CustomerStoryGridProps {
  stories: CustomerStory[];
}

const CustomerStoryGrid: React.SFC<CustomerStoryGridProps> = ({ stories }) => (
  <div className={styles.grid}>
    {stories.map((story) => (
      <CustomerStoryCard key={story.url} story={story} />
    ))}
  </div>
);

export default CustomerStoryGrid;
