import { Link } from 'gatsby';
import GatsbyImage, { FluidObject } from 'gatsby-image';
import React from 'react';
import { CustomerStory } from '../../types';
import ArrowGoTo from '../ui/ArrowGoTo';
import Text from '../ui/Text';
import styles from './CustomerStoryCard.module.scss';

interface CustomerStoryCardProps {
  story: CustomerStory;
}

const smallLogos = ['Alchemy', 'Fivetran', 'Radiant Law'];

const CustomerStoryCard: React.SFC<CustomerStoryCardProps> = ({
  story: {
    indexCover,
    company: { logo, name, industry },
    title,
    url,
  },
}) => (
  <Link to={url} className={styles.storyCard}>
    <div className={styles.coverImageContainer}>
      {indexCover.childImageSharp?.fluid && (
        <GatsbyImage
          fluid={indexCover.childImageSharp.fluid as FluidObject}
          className={styles.coverImage}
        />
      )}
    </div>
    <div className={styles.content}>
      <div
        className={`${styles.logo}${
          smallLogos.includes(name) ? ` ${styles.large}` : ''
        }`}
      >
        {logo?.publicURL && <img src={logo.publicURL} alt={name} />}
      </div>
      <div className={styles.center}>
        <h4 className={styles.title}>{title}</h4>
        <div className={styles.industry}>
          <Text caps muted xxSmall>
            {industry}
          </Text>
        </div>
      </div>
      <div className={styles.readMore}>
        <ArrowGoTo>Read story</ArrowGoTo>
      </div>
    </div>
  </Link>
);

export default CustomerStoryCard;
