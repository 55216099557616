import React from 'react';
import { CustomerStory } from '../../types';
import PageSection from '../ui/PageSection';
import Spacing from '../ui/Spacing';
import CustomerStoriesSectionHeader from './CustomerStoriesSectionHeader';
import CustomerStoryGrid from './CustomerStoryGrid';

interface CustomerStoryListProps {
  stories: CustomerStory[];
}

const CustomerStoriesList: React.SFC<CustomerStoryListProps> = ({
  stories,
}) => (
  <PageSection background="light">
    <Spacing vertical={10}>
      <CustomerStoriesSectionHeader
        title="Customer Stories"
        description="Learn about the ways our customers use Slab in their organizations"
      />
      <CustomerStoryGrid stories={stories} />
    </Spacing>
  </PageSection>
);

export default CustomerStoriesList;
