import {
  CompaniesYamlConnection,
  MarkdownRemark,
  MarkdownRemarkConnection,
} from '../graphql-types';
import { Company, CustomerStory } from '../types';

export const fullStory: (
  storyMarkdown: MarkdownRemark,
  companies: CompaniesYamlConnection,
) => CustomerStory = (storyMarkdown, companies) => {
  const { frontmatter, fields } = storyMarkdown;

  const company = companies.edges.find(
    (e) => e.node.fields?.slug === frontmatter?.company,
  )?.node as Company;

  return {
    ...frontmatter,
    ...fields,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/customer-stories/${fields?.slug}/`,
    company,
  } as CustomerStory;
};

const fullStories = ({
  companies,
  stories,
}: {
  companies: CompaniesYamlConnection;
  stories: MarkdownRemarkConnection;
}) =>
  stories.edges
    ? stories.edges.map((edge) => fullStory(edge.node, companies))
    : [];

export default fullStories;
