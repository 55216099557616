import { graphql } from 'gatsby';
import React from 'react';
import CustomerStoriesHeader from '../components/customer-stories/CustomerStoriesHeader';
import CustomerStoriesList from '../components/customer-stories/CustomerStoriesList';
import MarketingLayout from '../components/layout/MarketingLayout';
import { CustomerStory } from '../types';
import fullStories from '../utils/fullStories';

interface CustomerStoriesPageProps {
  stories: CustomerStory[];
}

const CustomerStoriesPage: React.SFC<CustomerStoriesPageProps> = ({
  stories,
}) => (
  <MarketingLayout
    title="You’re in great company"
    metaTitle="Customer Stories - Slab"
    noFooterPadding
  >
    <CustomerStoriesHeader />
    <CustomerStoriesList stories={stories} />
  </MarketingLayout>
);

export default ({ data }: { data: Parameters<typeof fullStories>[0] }) => (
  <CustomerStoriesPage stories={fullStories(data)} />
);

export const query = graphql`
  fragment CustomerStoryCardFragment on MarkdownRemark {
    fields {
      slug
    }
    frontmatter {
      company
      title
      indexCover {
        publicURL
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }

  fragment AllCustomerStoriesQuery on Query {
    stories: allMarkdownRemark(
      filter: {
        fields: { type: { eq: "customer-stories" } }
        frontmatter: { hide: { ne: true } }
      }
      sort: { fields: [fileAbsolutePath], order: DESC }
    ) {
      edges {
        node {
          id
          ...CustomerStoryCardFragment
        }
      }
    }
  }

  query {
    companies: allCompaniesYaml {
      edges {
        node {
          name
          industry
          logo {
            publicURL
          }
          fields {
            slug
          }
        }
      }
    }

    ...AllCustomerStoriesQuery
  }
`;
